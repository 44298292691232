import React from 'react'

import {Calculate, CalculationInput} from 'types/graphql/schema'

import {TableSection} from '../components/TableSection'
import {FieldSoB} from '../components/FieldSoB'
import {formatValue as f, formatUnits as fu} from '../utils'


export function SectionLongTermReturnsHealth(props: {
  calculate: (values: CalculationInput) => void
  calculationResult: Calculate
  isPdf: boolean
}) {
  const v = props.calculationResult
  return <TableSection
    colorGroupText="white"
    colorGroupBg="#C1CC00"
    colorTableBg="#F3F5CC"
    colorResultBg="#C1CC00"
    columns={[
      [
        '',
        '',
        {content: 'Incidence', cellProps: {colSpan: 2, sx: {textAlign: 'center', pt: '16px !important'}}},
        {content: 'Cost', cellProps: {sx: {textAlign: 'center', pt: '16px !important'}}},
        {content: `Cost (${v.currency.sign}/herd-cow/yr)`, cellProps: {colSpan: 3, sx: {textAlign: 'center', pt: '16px !important'}}}
      ],
      [
        '',
        {content: <><b>Units</b> (Incidence)</>},
        {
          content: 'Regular',
          tooltip: <>
            - The average incidence of diseases are based on a few references in the literature (Guard, 2008; Gonçalves
            et al., 2022).<br/>
            - Values can be adjusted based on current incidence if farm data is available.<br/>
          </>,
        },
        {
          content: 'Reduced',
          tooltip: <>
            - The reduced incidence was calculated based on the average percentage reduction of each disease with
            Methionine supplementation based on a few references (Osorio et al., 2013; Stangaferro et al., 2017; Zhou et
            al., 2016).<br/><br/>

            *Mastitis (clinical) avg reduction was of ~35% (35% of 2.0 is 0.7, and 2.0 - 0.7 = 1.3%.<br/>
            *Ketosis (clinical) avg reduction was of ~44% (44% of 6.0 is 2.64, and 6.0 - 2.64 = 3.4%.<br/>
            *Ketosis (subclinical) avg reduction was of ~44% (44% of 40 is 17.6, and 40 - 17.6 = 22%.<br/>
            *Metritis avg reduction was of ~43% (43% of 15 is 6.45, and 15 - 6.45 = 8.6%.<br/>
          </>,
        },
        {
          content: `${v.currency.sign}/case`,
          tooltip: <>
            - The disease management cost is based on data adapted from a few references (Guard, 2008; Kelton et al.,
            1998; McArt et al., 2015).<br/>
          </>,
        },
        'Current',
        'Reduced',
        'Difference',
      ]
    ]}
    data={[
      [
        'Mastitis (clinical)',
        fu('% of milking cows / mo'),
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsMastitisRegular" />},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsMastitisReduced" />},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsMastitisCostPerCase" />},
        f(v.longTermReturnsHealth.mastitis.current, 0),
        f(v.longTermReturnsHealth.mastitis.withProduct, 0),
        f(v.longTermReturnsHealth.mastitis.difference, 0),
      ],
      [
        'Ketosis (clinical)',
        fu('% of fresh cows'),
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsKetosisClinicalRegular" />},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsKetosisClinicalReduced" />},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsKetosisClinicalCostPerCase" />},
        f(v.longTermReturnsHealth.ketosisClinical.current, 0),
        f(v.longTermReturnsHealth.ketosisClinical.withProduct, 0),
        f(v.longTermReturnsHealth.ketosisClinical.difference, 0),
      ],
      [
        'Ketosis (subclinical)',
        fu('% of fresh cows'),
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsKetosisSubClinicalRegular" />},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsKetosisSubClinicalReduced" />},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsKetosisSubclinicalCostPerCase" />},
        f(v.longTermReturnsHealth.ketosisSubclinical.current, 0),
        f(v.longTermReturnsHealth.ketosisSubclinical.withProduct, 0),
        f(v.longTermReturnsHealth.ketosisSubclinical.difference, 0),
      ],
      [
        'Metritis',
        fu('% of fresh cows'),
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsMetritisRegular" />},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsMetritisReduced" />},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsMetritisCostPerCase" />},
        f(v.longTermReturnsHealth.metritis.current, 0),
        f(v.longTermReturnsHealth.metritis.withProduct, 0),
        f(v.longTermReturnsHealth.metritis.difference, 0),
      ],
      [
        {content: 'Total Savings', borderTop: true},
        {...fu(`${v.currency.sign}/cow/yr`), borderTop: true},
        {...f(''), borderTop: true},
        {...f(''), borderTop: true},
        {...f(''), borderTop: true},
        {...f(v.longTermReturnsHealth.totalSavings.current, 0), borderTop: true},
        {...f(v.longTermReturnsHealth.totalSavings.withProduct, 0), borderTop: true},
        {...f(v.longTermReturnsHealth.totalSavings.difference, 2), borderTop: true},
      ],
    ]}
    dataRowsCollapsible={[4]}
    groups={[
      {content: 'Health Status', firstRow: 0, titlePadding: '26px'},
    ]}
    groupsColumn=''
    isPdf={props.isPdf}
    result={{
      padding: '0px',
      tooltip: <>Gross Returns<br/>(Savings with Health)</>,
      title: 'Improved',
      subtitle: 'Health',
      units: `${v.currency.sign}/${v.resultsUnits}`,
      value: f(v.longTermReturnsHealth.totalSavingsFinal.difference, 2).text,
    }}
  />
}