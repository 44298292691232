import { Typography } from '@mui/material'
import * as React from 'react'

export function formatValue(value: number|string|null, decimals=2, multiplier=1, disabled=false) {
  let formattedValue
  if (value === null) {
    formattedValue = '---'
  } else if (typeof value === 'string') {
    formattedValue = value
  } else { // noinspection SuspiciousTypeOfGuard
    if (typeof value === 'number') {
        formattedValue = new Intl
          .NumberFormat(undefined, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
            useGrouping: true
          })
          .format(value * multiplier)
      } else {
        throw new Error(`Invalid value ${value} of type ${typeof value}`)
      }
  }
  return {
    content: <Typography variant="caption" sx={{color: disabled ? '#A70A2D' : undefined}}>{formattedValue}</Typography>,
    text: formattedValue
  }
}

export function formatUnits(units: string) {
  return {
    ...formatValue(units),
    cellProps: {sx: {textAlign: 'left', '& span': {opacity: .5}}}
  }
}
