import { Field, useFormikContext } from 'formik'
import { Select } from 'formik-mui'
import * as mui from '@mui/material'
import { Cancel as CancelIcon, CheckCircle as CheckIcon } from '@mui/icons-material'
import React from 'react'

import { Calculate, CalculationInput, Currency, Product, Standards } from 'types/graphql/schema'
import { convertCurrency, convertCurrencyInputs } from 'utils/units'
import { renderValues } from 'utils/values'

import { FieldSoB } from '../components/FieldSoB'
import { formatValue  as f, formatUnits as fu} from '../utils'
import { TableSection } from '../components/TableSection'
import { valuesType } from '../Index'

import smartlineImage from './assets/smartline.png'


export function SectionInvestment(props: {
  calculate: (values: CalculationInput) => void
  calculationResult: Calculate
  currencies: Currency[]
  disabledColumns: boolean[]
  setDisabledColumns: (columns: boolean[]) => void
  isPdf: boolean
  products: Product[]
  standards: Standards
}) {
  const v = props.calculationResult

  const { values, setValues } = useFormikContext<valuesType>()

  function ColumnToggle({children, i}: {children: React.ReactNode, i: number}) {
    return <mui.Box sx={{'&:hover button': {opacity: 1}}}>
      <mui.IconButton
        disableRipple
        onClick={() => {
          const newDisabledColumns = [...props.disabledColumns]
          newDisabledColumns[i] = !newDisabledColumns[i]
          props.setDisabledColumns(newDisabledColumns)
        }}
        size="small"
        sx={{
          '&:hover': {backgroundColor: 'none'},
          p: 0,
          opacity: props.disabledColumns[i] ? 1 : 0,
          ...(
            props.disabledColumns[i]
            ? {
                '&:hover .cancel': {display: 'none'},
                '&:hover .check': {display: 'block'},
              }
            : {}
          )
        }}
      >
        <CancelIcon className="cancel" sx={{opacity: .5}}/>
        <CheckIcon className="check" sx={{color: '#70B122', display: 'none'}}/>
      </mui.IconButton>
      <br/>
      {children}
    </mui.Box>
  }

  return <mui.Box>
    <mui.Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'row', mb: -.5}}>
      <img src={smartlineImage} alt="smartline" height="45" style={{marginTop: '7px'}}/>
      <Field
        component={Select}
        name="productId"
        error={false}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const productFeedingRates = props.standards.feedingRates.productFeedingRates
            .find(p => p.product.id === e.target.value)

          setValues(renderValues(
            {
              ...values,
              productId: e.target.value,
              productPrice: convertCurrency(
                props.products.find(p => p.id === e.target.value)!.price,
                1,
                v.currency,
              ),
              investmentProductGramsPerCowDayCloseUp: productFeedingRates!.closeUp,
              investmentProductGramsPerCowDayFresh: productFeedingRates!.fresh,
              investmentProductGramsPerCowDayEarlyMidLact: productFeedingRates!.earlyMidLact,
              investmentProductGramsPerCowDayLateLact: productFeedingRates!.lateLact,
            }, 
            v.currency.code
          ))
            .then(() => props.calculate(values as CalculationInput))
        }}
        sx={{'& fieldset': {border: 'none'}}}
      >
        {props.products.map(product => (
          <mui.MenuItem key={product.id} value={product.id}>
            <span dangerouslySetInnerHTML={{ __html: product.name }}/>
          </mui.MenuItem>
        ))}
      </Field>
      <mui.Stack direction="row" sx={{alignItems: 'center'}} gap={1}>
        <mui.Typography variant="body2" sx={{color: '#7F7F7F', ml: 3, verticalAlign: 'middle'}}>
          {v.currency.sign}
        </mui.Typography>
        <FieldSoB calculate={props.calculate} isPdf={props.isPdf} name="productPrice" sx={{backgroundColor: '#F2F2F2', borderRadius: '5px', width: '50px', px: '6px'}}/>
        <mui.Typography variant="body2" sx={{color: '#7F7F7F'}}>
          / kg of product
        </mui.Typography>
      </mui.Stack>
      <Field
        component={Select}
        name="currencyId"
        error={false}
        onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
          const oldCurrency = props.currencies.find(c => c.id === values.currencyId) as Currency
          const newCurrency = props.currencies.find(c => c.id === e.target.value) as Currency
          const newValues = await convertCurrencyInputs(
            props.products,
            (values: valuesType) => setValues(values, false),
            oldCurrency.conversionRate,
            newCurrency,
            values,
          )
          props.calculate(newValues as CalculationInput)
        }}
        sx={{
          color: '#7F7F7F',
          '& .MuiSelect-select': {py: 0},
          '& fieldset': {border: 'none'},
        }}
      >
        {props.currencies.map(currency => (
          <mui.MenuItem key={currency.id} value={currency.id}>
            {currency.code}
          </mui.MenuItem>
        ))}
      </Field>
    </mui.Box>
    <TableSection
      colorGroupText="white"
      colorGroupBg="#00A3C4"
      colorTableBg="#E1FAFF"
      colorResultBg="#00A3C4"
      columns={[[
        '',
        {content: <mui.Box sx={{verticalAlign: 'bottom'}}>Units</mui.Box>},
        {content: <ColumnToggle i={0}>Far-off</ColumnToggle>, tooltip: 'Far-off = 35 d'},
        {content: <ColumnToggle i={1}>Close-up</ColumnToggle>, tooltip: 'Close-up = 25 d'},
        {content: <ColumnToggle i={2}>Fresh</ColumnToggle>, tooltip: 'Fresh = 1 to 30 DIM'},
        {content: <ColumnToggle i={3}>Early-Mid lact.</ColumnToggle>, tooltip: 'Early-Mid lact. = 30 to 235 DIM'},
        {content: <ColumnToggle i={4}>Late lact.</ColumnToggle>, tooltip: 'Late lact. = 235 to 305 DIM'},
      ]]}
      data={[
        [
          {
            content: 'Herd Structure',
            cellProps: {rowSpan: 2},
            borderBottom: true,
          },
          {
            ...fu('%/phase'),
            tooltip: <>
              - These numbers refer to the percentage of cows within each group.<br/>
              - The sum for those numbers should total 100.<br/><br/>
              Additional Notes:<br/>
              - If you want to get an estimate of AA Balancing investment per specific group,
              then put 0 in this row for the group you want to exclude.
            </>
          },
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[0]} isPdf={props.isPdf} name="investmentHerdStructureFarOff" />},
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[1]} isPdf={props.isPdf} name="investmentHerdStructureCloseUp" />},
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[2]} isPdf={props.isPdf} name="investmentHerdStructureFresh" />},
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[3]} isPdf={props.isPdf} name="investmentHerdStructureEarlyMidLact" />},
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[4]} isPdf={props.isPdf} name="investmentHerdStructureLateLact" />},
        ],
        [
          {
            ...fu('cow #/phase'),
            borderBottom: true,
            tooltip: <>
              - The number of cows per phase is calculated as: % of cows within each phase (row above) × total # of cows
              in the herd (herd size # entered, pink colored).<br/>
              - Data in this row represent the number of cows within each phase.<br/>
              - It is dependent upon the % of cows within each phase (user-entered in the row above)
              and the herd size (also user-entered).
            </>
          },
          {...f(v.investment.cowsAmount.farOff, 0, 1, props.disabledColumns[0]), borderBottom: true},
          {...f(v.investment.cowsAmount.closeUp, 0, 1, props.disabledColumns[1]), borderBottom: true},
          {...f(v.investment.cowsAmount.fresh, 0, 1, props.disabledColumns[2]), borderBottom: true},
          {...f(v.investment.cowsAmount.earlyMidLact, 0, 1, props.disabledColumns[3]), borderBottom: true},
          {...f(v.investment.cowsAmount.lateLact, 0, 1, props.disabledColumns[4]), borderBottom: true},
        ],
        [
          {content: v.product.name.replace(/®/g, ''), cellProps: {rowSpan: 2}, borderBottom: true},
          {
            ...fu('g/cow/d'),
            tooltip: <>
              - The default is set for all lactating cows (Fresh, Early-Mid, & Late) to be feeding similar dose
              as if they were all feeding a 1-group TMR, however, the user is able to alter feeding rate per group
              accordingly if their current farm group cows differently.<br/>
              - On the drop-down menu above (right by the product name) you have the option to pick Smartamine M,
              MetaSmart Dry, or MetaSmart Liquid.<br/>
              - When you change products, the standard product inclusion rate will change accordingly,
              but be aware that the product pricing does not change.<br/>
              - Therefore, if you want to use MetaSmart instead of Smartamine M, besides of switching product names
              using drop-down list, please also update the price of the product manually.<br/><br/>

              Additional Notes:<br/>
              - If you want to exclude a group from the AA Balancing investment estimates,
              do not place a 0 in this row.<br/>
              - Placing a 0 for a specific group here (for instance, Late lact. cows), will create a dilution issue
              as the those cows (their TMR cost) within that group will still be accounted for.<br/>
              - To accomplish that, please place a 0 in the Herd Structure (%/phase) row
              for the group you want to exclude, which will eliminate the cows from the group,
              and provide a more accurate estimate.<br/>
            </>
          },
          f(null, 2, 1, props.disabledColumns[0]),
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[1]} isPdf={props.isPdf} name="investmentProductGramsPerCowDayCloseUp" />},
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[2]} isPdf={props.isPdf} name="investmentProductGramsPerCowDayFresh" />},
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[3]} isPdf={props.isPdf} name="investmentProductGramsPerCowDayEarlyMidLact" />},
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[4]} isPdf={props.isPdf} name="investmentProductGramsPerCowDayLateLact" />},
        ],
        [
          {...fu(`${v.currency.sign}/cow/d`), borderBottom: true},
          {...f(v.investment.productPricePerCowDay.farOff, 3, 1, props.disabledColumns[0]), borderBottom: true},
          {...f(v.investment.productPricePerCowDay.closeUp, 3, 1, props.disabledColumns[1]), borderBottom: true},
          {...f(v.investment.productPricePerCowDay.fresh, 3, 1, props.disabledColumns[2]), borderBottom: true},
          {...f(v.investment.productPricePerCowDay.earlyMidLact, 3, 1, props.disabledColumns[3]), borderBottom: true},
          {...f(v.investment.productPricePerCowDay.lateLact, 3, 1, props.disabledColumns[4]), borderBottom: true},
        ],
        [
          {
            content: 'DMI',
            tooltip: <>
              - Dry Matter Intake here refers to how much feed (TMR) cows are consuming per phase.<br/>
              - The DMI and the TMR cost will not affect the investment cost, unless the +DMI button is
              pressed.<br/><br/>

              Additional Notes:<br/>
              - If you want to exclude a group from the AA Balancing investment estimates,
              do not place a 0 in this row.<br/>
              - To accomplish that, please place a 0 in the Herd Structure (%/phase) row for the group you want to
              exclude, which will eliminate the cows from the group, and provide a more accurate estimate.<br/>
            </>
          },
          fu(`${v.weightUnits}/cow/d`),
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[0]} isPdf={props.isPdf} name="investmentDmiFarOff" />},
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[1]} isPdf={props.isPdf} name="investmentDmiCloseUp" />},
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[2]} isPdf={props.isPdf} name="investmentDmiFresh" />},
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[3]} isPdf={props.isPdf} name="investmentDmiEarlyMidLact" />},
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[4]} isPdf={props.isPdf} name="investmentDmiLateLact" />},
        ],
        [
          {
            content: <i> + <span dangerouslySetInnerHTML={{ __html: v.product.name.replace(/®/g, '') }}/></i>,
            tooltip: <>
              - If you only see dash in this row, it means that DMI of Methionine-supplemented cows equals that of
              non-supplemented cows.<br/>
              - But, if you press the orange +DMI orange button, it increases DMI of transition cows fed methionine; an
              average increased DMI based on a couple of transition cow studies testing Methionine supplementation.<br/>
              - If using |lb| version, the button will add 1 and 3 lb to DMI of Close-up and Fresh cows,
              respectively.<br/>
              - If using |kg| version, it will add 0.5 and 1.4 kg of DMI, respectively.<br/><br/>

              References:
              Strzetelski et al. 2009; Osorio et al. 2013; Zhou et al. 2016; Leao et al. 2019; Potts et al. 2020;<br/>
              Michelotti et al. 2021; France et al. 2022.
            </>,
            borderBottom: true,
          },
          {...fu(`${v.weightUnits}/cow/d`), borderBottom: true},
          {
            ...f(v.investment.dmiWithProduct.farOff ? `+${v.investment.dmiWithProduct.farOff}` : null, 2, 1, props.disabledColumns[0]),
            cellProps: {sx: (v.investment.dmiWithProduct.farOff ? {color: '#EC671F', '& span': {fontWeight: 700}} : {})},
            borderBottom: true,
          },
          {
            ...f(v.investment.dmiWithProduct.closeUp ? `+${v.investment.dmiWithProduct.closeUp}` : null, 2, 1, props.disabledColumns[1]),
            cellProps: {sx: (v.investment.dmiWithProduct.closeUp ? {color: '#EC671F', '& span': {fontWeight: 700}} : {})},
            borderBottom: true,
          },
          {
            ...f(v.investment.dmiWithProduct.fresh ? `+${v.investment.dmiWithProduct.fresh}` : null, 2, 1, props.disabledColumns[2]),
            cellProps: {sx: (v.investment.dmiWithProduct.fresh ? {color: '#EC671F', '& span': {fontWeight: 700}} : {})},
            borderBottom: true,
          },
          {
            ...f(v.investment.dmiWithProduct.earlyMidLact ? `+${v.investment.dmiWithProduct.earlyMidLact}` : null, 2, 1, props.disabledColumns[3]),
            cellProps: {sx: {color: v.investment.dmiWithProduct.earlyMidLact ? 'darkred' : undefined}},
            borderBottom: true,
          },
          {
            ...f(v.investment.dmiWithProduct.lateLact ? `+${v.investment.dmiWithProduct.lateLact}` : null, 2, 1, props.disabledColumns[4]),
            cellProps: {sx: {color: v.investment.dmiWithProduct.lateLact ? 'darkred' : undefined}},
            borderBottom: true,
          },
        ],
        [
          {
            content: 'TMR Cost',
            tooltip: <>
              - This row is for the user to enter the total cost of the TMR (per lb or kg of DM) without Methionine
              supplementation.<br/>
              - The DMI and the TMR cost will not affect the investment cost, unless the +DMI orange button is pressed.
            </>
          },
          fu(`${v.currency.sign}/${v.weightUnits} DM`),
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[0]} isPdf={props.isPdf} name="investmentDmCostCurrentFarOff" />},
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[1]} isPdf={props.isPdf} name="investmentDmCostCurrentCloseUp" />},
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[2]} isPdf={props.isPdf} name="investmentDmCostCurrentFresh" />},
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[3]} isPdf={props.isPdf} name="investmentDmCostCurrentEarlyMidLact" />},
          {content: <FieldSoB calculate={props.calculate} disabled={props.disabledColumns[4]} isPdf={props.isPdf} name="investmentDmCostCurrentLateLact" />},
        ],
        [
          {
            content: <i> + <span dangerouslySetInnerHTML={{__html: v.product.name.replace(/®/g, '')}}/></i>,
            tooltip: <>
              - This is calculated as: Adjusted Feed Costs (per cow per day) ÷ DMI (lb or kg per cow per day).<br/>
              - This row calculates total cost of the TMR (per lb or kg of DM) including Methionine
              supplementation.<br/>
              - If the +DMI orange button is pressed, then the TMR cost for Close-up & Fresh groups of cows fed
              Methionine will account for the increased cost from increased DMI.<br/>
            </>,
            borderBottom: true,
          },
          {...fu(`${v.currency.sign}/${v.weightUnits} DM`), borderBottom: true},
          {...f(v.investment.dmCostWithProduct.farOff, 3, 1, props.disabledColumns[0]), borderBottom: true},
          {...f(v.investment.dmCostWithProduct.closeUp, 3, 1, props.disabledColumns[1]), borderBottom: true},
          {...f(v.investment.dmCostWithProduct.fresh, 3, 1, props.disabledColumns[2]), borderBottom: true},
          {...f(v.investment.dmCostWithProduct.earlyMidLact, 3, 1, props.disabledColumns[3]), borderBottom: true},
          {...f(v.investment.dmCostWithProduct.lateLact, 3, 1, props.disabledColumns[4]), borderBottom: true},
        ],
        [
          {
            content: 'TMR Cost',
            tooltip: <>
              - This is calculated as: Current TMR Costs (per lb or kg of DM) × DMI (lb or kg per cow per day).<br/>
            </>,
          },
          fu(`${v.currency.sign}/cow/d`),
          f(v.investment.feedCostPerCowDay.farOff, 2, 1, props.disabledColumns[0]),
          f(v.investment.feedCostPerCowDay.closeUp, 2, 1, props.disabledColumns[1]),
          f(v.investment.feedCostPerCowDay.fresh, 2, 1, props.disabledColumns[2]),
          f(v.investment.feedCostPerCowDay.earlyMidLact, 2, 1, props.disabledColumns[3]),
          f(v.investment.feedCostPerCowDay.lateLact, 2, 1, props.disabledColumns[4]),
        ],
        [
          {
            content: <i> + <span dangerouslySetInnerHTML={{__html: v.product.name.replace(/®/g, '')}}/></i>,
            tooltip: <>
              - This is calculated as: Adjusted Feed Costs ($/lb DM) × DMI (lb or kg per cow per day).<br/>
              - This measure can also be assessed through the sum of Current Feed Costs (per cow per day) + Methionine
              Supplementation Cost (per cow per day).<br/>
              - If the +DMI orange button is pressed, then the TMR cost for Close-up & Fresh groups of cows fed
              Methionine will account for the increased cost from increased DMI.<br/>
            </>,
          },
          fu(`${v.currency.sign}/cow/d`),
          f(v.investment.feedCostPerCowDayWithProduct.farOff, 2, 1, props.disabledColumns[0]),
          f(v.investment.feedCostPerCowDayWithProduct.closeUp, 2, 1, props.disabledColumns[1]),
          {
            ...f(v.investment.feedCostPerCowDayWithProduct.fresh, 2, 1, props.disabledColumns[2]),
            cellProps: {sx: (values.aabCents ? {color: 'green', '& span': {fontWeight: 700}} : {})}
          }, {
            ...f(v.investment.feedCostPerCowDayWithProduct.earlyMidLact, 2, 1, props.disabledColumns[3]),
            cellProps: {sx: (values.aabCents ? {color: 'green', '& span': {fontWeight: 700}} : {})}
          }, {
            ...f(v.investment.feedCostPerCowDayWithProduct.lateLact, 2, 1, props.disabledColumns[4]),
            cellProps: {sx: (values.aabCents ? {color: 'green', '& span': {fontWeight: 700}} : {})}
          },
        ],
        [
          {content: 'TMR Cost', borderTop: true},
          {...fu(`${v.currency.sign}/cow/yr`), borderTop: true},
          {
            content: f(v.investment.totalFeedCostPerCowYear, 0).content,
            cellProps: {colSpan: 3},
            borderTop: true,
          }, {
            ...(f(`${f(v.investment.totalFeedCostPerCowYearDifference, 2).text} Difference`)),
            cellProps: {colSpan: 2, rowSpan: 2},
            borderTop: true,
          },
        ],
        [
          {content: <i> + <span dangerouslySetInnerHTML={{__html: v.product.name.replace(/®/g, '')}}/></i>},
          fu(`${v.currency.sign}/cow/yr`),
          {
            content: f(v.investment.totalFeedCostPerCowYearWithProduct, 0).content,
            cellProps: {colSpan: 3}
          },
        ],
      ]}
      dataRowsCollapsible={[0, 1, 4, 5, 6, 7, 10, 11]}
      groups={[
        {
          content: 'Investment',
          firstRow: 0,
          titlePadding: '26px',
        },
      ]}
      isPdf={props.isPdf}
      result={{
        padding: '0px',
        title: <mui.Box sx={{verticalAlign: 'bottom'}}>Additional Cost with</mui.Box>,
        subtitle: 'AA Balancing',
        units: `${v.currency.sign}/${v.resultsUnits}`,
        value: f(v.investment.totalFeedCostDifference, 2).text,
      }}
    />
  </mui.Box>
}