import * as mui from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'

import {Field, FieldProps} from '../Field'
import {useFormikContext} from 'formik'
import {CalculationInput} from 'types/graphql/schema'


export function FieldSoB({
  calculate,
  isPdf,
  ...props
}: {
  calculate: (values: CalculationInput) => void
  isPdf: boolean,
} & FieldProps) {
  const theme = useTheme()
  const { values } = useFormikContext<any>()

  if (isPdf) {
    return (
      <mui.Typography
        sx={{
          color: props.disabled ? '#A70A2D' : '#0000FF',
          fontSize: theme.typography.caption.fontSize,
          fontWeight: 'bold',
          textAlign: 'center',
          ...props.sx,
        }}
      >
        {values[props.name]}
      </mui.Typography>
    )
  }
  return (
    <Field
      {...props}
      calculate={calculate}
      sx={{
        '& fieldset': {
          border: 'none',
          p: 0,
        },
        '& input': {
          color: '#0000FF',
          fontSize: theme.typography.caption.fontSize,
          fontWeight: 'bold',
          p: 0,
          textAlign: 'center',
        },
        '& input.Mui-disabled': {
          color: "#A70A2D",
          WebkitTextFillColor: '#A70A2D',
        },
        '& .Mui-error input': {
          backgroundColor: '#FBC1CD',
        },
        '& .Mui-error.MuiFormHelperText-root': {
          display: 'none',
        },
        ...props.sx,
      }}
    />
  )
}