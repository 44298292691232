import * as mui from '@mui/material'
import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import SolidGauge from 'highcharts/modules/solid-gauge'

import {Calculate} from 'types/graphql/schema'

import {formatValue as f} from '../../utils'


HighchartsMore(Highcharts)
SolidGauge(Highcharts)


type Chart = Highcharts.Chart & {
  customElements?: { [key: string]: Highcharts.SVGElement[] }
}

const createCurvedText = (chart: Chart, text: string, seriesIdx: number, angle: number, id: string) => {
  const renderer = chart.renderer
  const series = chart.series[seriesIdx]

  const spacingDiff = 10  // chart.spacingTop - chart.spacingBottom
  const centerX = Math.round(chart.plotWidth/2) + 9.5
  const centerY = Math.round(chart.plotHeight/2) + 9.5 + spacingDiff
  const r = (
    Math.min(centerX, centerY)  // radius
    - 10  // padding
    // @ts-ignore
    - series.barW * (seriesIdx == 2 ? 1 : seriesIdx+1)  // select lane
    - spacingDiff
    + 6  // center text
  )

  const textOffset = (
    text.length * 0.07  // text length
    / (r / 50)  // radius
    * (seriesIdx === 2 ? -1 : 1)  // flipped text
  )
  angle -= textOffset
  const startX = centerX + r * Math.cos(angle)
  const startY = centerY + r * Math.sin(angle)

  let pathData
  if (seriesIdx == 2) {
    pathData = [
      // Move to the starting point
      `M ${startX},${startY}`,
      // Draw the arc to the opposite side of the circle
      `A ${r},${r} 0 1,0 ${centerX + r * Math.cos(angle + Math.PI)},${centerY + r * Math.sin(angle + Math.PI)}`,
      // Draw the arc back to the starting point to complete the circle
      `A ${r},${r} 0 1,0 ${startX},${startY}`
    ].join(' ')
  } else {
    pathData = [
      // Move to the starting point
      `M ${startX},${startY}`,
      // Draw the arc to the opposite side of the circle
      `A ${r},${r} 0 1,1 ${centerX + r * Math.cos(angle + Math.PI)},${centerY + r * Math.sin(angle + Math.PI)}`,
      // Draw the arc back to the starting point to complete the circle
      `A ${r},${r} 0 1,1 ${startX},${startY}`
    ].join(' ')
  }
  const path = renderer.path()
    .attr({
      d: pathData,
      id,
      fill: 'none',
      stroke: 'none',
    })

  const textElement = renderer.text('', 0, 0)
    .css({
      color: '#000',
      stroke: ['#C1CC00', '#CB6075', '#00A3C4'][seriesIdx],
      strokeWidth: 0.5,
      fontSize: '16px',
      fontWeight: '1000',
      pointerEvents: 'none',
    })

  // Remove existing path if it exists
  if (chart.customElements![id]) {
    chart.customElements![id].map(e => e.destroy())
  }
  chart.customElements![id] = [path, textElement]

  const textPathElement = document.createElementNS('http://www.w3.org/2000/svg', 'textPath')
  textPathElement.setAttributeNS(null, 'href', `#${id}`)
  textPathElement.textContent = text


  textElement.element.appendChild(textPathElement)

  chart.renderer.box.appendChild(path.element)
  chart.renderer.box.appendChild(textElement.element)
  return path
}

export function Summary(props: {calculationResult: Calculate}) {
  const v = props.calculationResult
  const chartConfig = {
    accessibility: {enabled: false},
    chart: {
      backgroundColor: 'transparent',
      type: 'column',
      inverted: true,
      polar: true,
      height: 200,
      spacingTop: 20,
      spacingBottom: 10,
      events: {
        // Render custom dataLabels (text on the chart)
        render: function(this: Chart) {
          const chart = this

          // Store references to custom elements
          if (!chart.customElements) {
            chart.customElements = {}
          }

          chart.series.forEach((series, i) => {
            series.points.forEach((point, j) => {
              if (point.y === null) return
              const point_y = point.y || 0
              const angle = point.shapeArgs!.start + (point.shapeArgs!.end - point.shapeArgs!.start) / 2
              const rotationFlipNegative = 0//Math.PI / 2 * (point_y > 0 ? 1 : -1)
              const finalAngle = angle + rotationFlipNegative
              const text = `${point_y > 0 ? '+' : ''}${f(point_y, Math.abs(point_y) >= 1000 ? 0 : 2).text}`
              const id = `path-${i}-${j}`

              createCurvedText(chart, point_y ? text : '', i, finalAngle, id)
            })
          })
        }
      },
    },
    credits: {enabled: false},
    legend: {enabled: false},
    pane: {
      size: '100%',
      innerSize: '50%',
      startAngle: (
        v.investment.totalFeedCostDifference
        / (v.iofcShortAndLongTerm - v.investment.totalFeedCostDifference)
        * 360
        - 90
      ),
    },
    plotOptions: {
      column: {
        borderRadius: 0,
        borderWidth: 0,
        groupPadding: 0,
        pointPadding: -0.01,
        stacking: 'normal',
        dataLabels: {
          enabled: false // Disable default data labels
        },
      },
      series: {
        states: {
          hover: {
            enabled: false // Disable hover effect on the series level
          }
        },
        marker: {
          states: {
            hover: {
              enabled: false // Disable hover effect on individual points
            },
          }
        }
      }
    },
    series: [
      {
        data: [parseFloat(v.iofcShortAndLongTerm.toFixed(2)), null],
        color: '#C1CC00',
        pointPlacement: 'between',
      },
      {
        data: [null, parseFloat(v.iofcShortTerm.toFixed(2))],
        color: '#CB6075',
        pointPlacement: 'between',
      },
      {
        data: [
          parseFloat(v.investment.totalFeedCostDifference.toFixed(2)),
          parseFloat(v.investment.totalFeedCostDifference.toFixed(2))
        ],
        color: '#00A3C4',
        pointPlacement: 'between',
      }
    ],
    title: { text: '' },
    tooltip: {
      formatter: function(this: Highcharts.TooltipFormatterContextObject): any {
        switch (this.series.index) {
          case 0:
            return `
              Milk + Repro + Health<sub style="color: gray; font-size: 80%; line-height: 0;"> (Gross Returns)</sub> - Investment
            `
          case 1:
            return `
              Milk<sub style="color: gray; font-size: 80%; line-height: 0;"> (Gross Returns)</sub> - Investment`
          case 2:
            return `Investment`
        }
      },
      followPointer: true
    },
    xAxis: {
      tickInterval: 0,
      labels: {enabled: false},
      lineWidth: 0,
      gridLineWidth: 0,
      categories: []
    },
    yAxis: {
      gridLineWidth: 0,
      labels: { enabled: false },
    }
  }
  return <mui.Box sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  }}>
    <mui.Typography variant="caption" sx={{fontWeight: 'bold', textAlign: 'center', mb: .5}}>
      Investment vs. Net Returns
    </mui.Typography>
    <mui.Box sx={{
      display: 'flex',
      backgroundColor: '#F5F5F5',
      borderRadius: '20px',
      justifyContent: 'center',
    }}>
      <mui.Box sx={{width: '100%', pb: '10px', position: 'relative'}}>
        {/* Center */}
        <mui.Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '50%',
            height: '170px',
            left: '50%',
            position: 'absolute',
            textAlign: 'center',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '170px',
          }}
        >
          <mui.Box
            sx={{
              left: '50%',
              opacity: .7,
              position: 'absolute',
              textAlign: 'center',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <mui.Typography sx={{fontSize: '.9rem', lineHeight: 1.25}}><b>IOFC</b></mui.Typography>
            <mui.Typography sx={{fontSize: '.7rem', lineHeight: 1.25}}>{v.currency.sign}/{v.resultsUnits}</mui.Typography>
          </mui.Box>
        </mui.Box>
        {/* Left */}
        <mui.Box
          sx={{
            position: 'absolute',
            top: '16px',
            left: '22px',
            textAlign: 'center',
          }}
        >
          <mui.Typography sx={{color: '#A70A2D', fontSize: '1.1rem', lineHeight: 1}}><b>Milk</b></mui.Typography>
          <mui.Typography sx={{fontSize: '.9rem', lineHeight: 1.7}}><b>{f(v.roiShortTerm).text}</b> ROI</mui.Typography>
        </mui.Box>
        {/* Right */}
        <mui.Box
          sx={{
            position: 'absolute',
            top: '16px',
            right: '20px',
            textAlign: 'center',
            width: '75px'
          }}
        >
          <mui.Typography sx={{color: '#a4ad00', fontSize: '1.05rem', lineHeight: 1}}><b>Milk, Repro, & Health</b></mui.Typography>
          <mui.Typography sx={{fontSize: '.9rem', lineHeight: 1.7}}><b>{f(v.roiShortAndLongTerm).text}</b> ROI</mui.Typography>
        </mui.Box>
        <HighchartsReact highcharts={Highcharts} options={chartConfig}/>
      </mui.Box>
    </mui.Box>
  </mui.Box>
}