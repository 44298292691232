import {Currency, Units} from 'types/graphql/schema'

import {Product} from 'types/graphql/schema'
import {valuesType} from 'pages/Index/Index'
import {renderValues} from './values'


export function convertCurrency(value: number, prevRate: number, currency: Currency) {
  return (value / prevRate * currency.conversionRate)
}


export async function convertCurrencyInputs(
  products: Product[],
  setValues: (values: valuesType) => void,
  prevRate: number,
  currency: Currency,
  newValues: valuesType
) {
  const initialProductPrice = products.find(p => p.id === newValues.productId)!.price
  const convertedValues = renderValues(
    {
      ...newValues,
      currencyId: currency.id,
      productPrice: initialProductPrice * currency.conversionRate,
      investmentDmCostCurrentFarOff: convertCurrency(newValues.investmentDmCostCurrentFarOff, prevRate, currency),
      investmentDmCostCurrentCloseUp: convertCurrency(newValues.investmentDmCostCurrentCloseUp, prevRate, currency),
      investmentDmCostCurrentFresh: convertCurrency(newValues.investmentDmCostCurrentFresh, prevRate, currency),
      investmentDmCostCurrentEarlyMidLact: convertCurrency(newValues.investmentDmCostCurrentEarlyMidLact, prevRate, currency),
      investmentDmCostCurrentLateLact: convertCurrency(newValues.investmentDmCostCurrentLateLact, prevRate, currency),
      shortTermReturnsMilkFmmoPrice: convertCurrency(newValues.shortTermReturnsMilkFmmoPrice, prevRate, currency),
      shortTermReturnsFatFmmoPrice: convertCurrency(newValues.shortTermReturnsFatFmmoPrice, prevRate, currency),
      shortTermReturnsProteinFmmoPrice: convertCurrency(newValues.shortTermReturnsProteinFmmoPrice, prevRate, currency),
      shortTermReturnsOtherSolidsFmmoPrice: convertCurrency(newValues.shortTermReturnsOtherSolidsFmmoPrice, prevRate, currency),
      shortTermReturnsPremiumFmmoPrice: convertCurrency(newValues.shortTermReturnsPremiumFmmoPrice, prevRate, currency),
      longTermReturnsCalfValueCurrent: convertCurrency(newValues.longTermReturnsCalfValueCurrent, prevRate, currency),
      longTermReturnsReproductionCostCurrent: convertCurrency(newValues.longTermReturnsReproductionCostCurrent, prevRate, currency),
      longTermReturnsReplacementCostCurrent: convertCurrency(newValues.longTermReturnsReplacementCostCurrent, prevRate, currency),
      longTermReturnsCullValueCurrent: convertCurrency(newValues.longTermReturnsCullValueCurrent, prevRate, currency),
      longTermReturnsMastitisCostPerCase: convertCurrency(newValues.longTermReturnsMastitisCostPerCase, prevRate, currency),
      longTermReturnsKetosisClinicalCostPerCase: convertCurrency(newValues.longTermReturnsKetosisClinicalCostPerCase, prevRate, currency),
      longTermReturnsKetosisSubclinicalCostPerCase: convertCurrency(newValues.longTermReturnsKetosisSubclinicalCostPerCase, prevRate, currency),
      longTermReturnsMetritisCostPerCase: convertCurrency(newValues.longTermReturnsMetritisCostPerCase, prevRate, currency),
    },
    currency.code,
  )
  await setValues(convertedValues)
  return convertedValues
}

export async function convertMassInputs(
  setValues: (values: valuesType) => void,
  units: Units,
  newValues: valuesType,
  currencyCode: string,
  submit=true,
) {
  const k = units === Units.IMPERIAL ? 2.20462 : 1 / 2.20462
  function convert(value: number, reverse?: boolean) {
    return value * (reverse ? 1 / k : k)
  }
  const convertedValues = renderValues(
    {
      ...newValues,
      investmentDmiFarOff: convert(newValues.investmentDmiFarOff),
      investmentDmiCloseUp: convert(newValues.investmentDmiCloseUp),
      investmentDmiFresh: convert(newValues.investmentDmiFresh),
      investmentDmiEarlyMidLact: convert(newValues.investmentDmiEarlyMidLact),
      investmentDmiLateLact: convert(newValues.investmentDmiLateLact),
      investmentDmCostCurrentFarOff: convert(newValues.investmentDmCostCurrentFarOff, true),
      investmentDmCostCurrentCloseUp: convert(newValues.investmentDmCostCurrentCloseUp, true),
      investmentDmCostCurrentFresh: convert(newValues.investmentDmCostCurrentFresh, true),
      investmentDmCostCurrentEarlyMidLact: convert(newValues.investmentDmCostCurrentEarlyMidLact, true),
      investmentDmCostCurrentLateLact: convert(newValues.investmentDmCostCurrentLateLact, true),
      shortTermReturnsMilkCurrent: convert(newValues.shortTermReturnsMilkCurrent),
      shortTermReturnsMilkFmmoPrice: convert(newValues.shortTermReturnsMilkFmmoPrice, true),
      shortTermReturnsFatFmmoPrice: convert(newValues.shortTermReturnsFatFmmoPrice, true),
      shortTermReturnsProteinFmmoPrice: convert(newValues.shortTermReturnsProteinFmmoPrice, true),
      shortTermReturnsOtherSolidsFmmoPrice: convert(newValues.shortTermReturnsOtherSolidsFmmoPrice, true),
      shortTermReturnsPremiumFmmoPrice: convert(newValues.shortTermReturnsPremiumFmmoPrice, true),
      longTermReturnsCullValueCurrent: convert(newValues.longTermReturnsCullValueCurrent, true),
      longTermReturnsMinimumMilkToBreedCurrent: convert(newValues.longTermReturnsMinimumMilkToBreedCurrent),
    },
    currencyCode
  )
  if (submit) {
    await setValues(convertedValues)
  }
  return convertedValues
}