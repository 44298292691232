import React from 'react'

import {CalculationInput} from 'types/graphql/schema'

import {Field, FieldProps} from '../../../components/Field'


export function NumberField({
  calculate,
  ...props
}: {
  calculate: (values: CalculationInput) => void,
} & FieldProps) {

  return <Field
    {...props}
    calculate={calculate}
    sx={{
      '& .MuiInputBase-root': {
        // backgroundColor: lightColorScheme.bg,
        borderRadius: '6px',
        // color: lightColorScheme.textAccent,
      },
      '& .MuiInputAdornment-root p': {
        // color: lightColorScheme.textAccent,
      },
      ...props.sx,
    }}
  />
}