import * as mui from '@mui/material'
import {Field, Form, Formik} from 'formik'
import {TextField} from 'formik-mui'
import React from 'react'
import {Navigate, useParams, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'

import {Link} from 'components/Link'
import {routes} from 'routes'
import {graphqlClient, useQuery} from 'utils/graphql'

import logoIcon from '../assets/logo.png'


const validationSchema = Yup.object().shape({
  password: Yup.string().required('Required').min(8, '8 characters minimum'),
  password2: Yup.string().required('Required').oneOf([Yup.ref('password')], 'Passwords must match'),
})
type valuesType = Yup.InferType<typeof validationSchema>

export function ResetPassword() {
  const [commonErrors, setCommonErrors] = React.useState<string[]>([])
  const [fieldErrors, setFieldErrors] = React.useState<{[k: string]: string}>({})

  const { token } = useParams()

  const navigate = useNavigate()

  const tokenIsValid = useQuery(
    {resetPasswordTokenValid: [{token}]},
    'resetPasswordTokenValid'
  )

  function handleSubmit(
    values: valuesType,
    {setSubmitting}: {setSubmitting: (isSubmitting: boolean) => void}
  ) {
    setCommonErrors([])
    graphqlClient.chain.mutation
      .resetPassword({token: token, ...values})
      .execute({__scalar: 1})
      .then(() => navigate(routes.LOGIN+'?reset=true'))
      .catch(e => {
        if (e.errors[0].extensions) {
          setCommonErrors(e.errors[0].extensions.common_errors || [])
          setFieldErrors(e.errors[0].extensions.field_errors || {})
        } else {
          throw e
        }
      })
      .finally(() => setSubmitting(false))
  }

  if (tokenIsValid.spinner) return tokenIsValid.spinner

  return (
    <mui.Container component="main" maxWidth="xs">
      <mui.Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={logoIcon} alt="logo" height="100"/>
        <mui.Typography component="h1" variant="h5" mt={4}>
          Reset password
        </mui.Typography>
        <Formik
          initialValues={{password: '', password2: ''}}
          onSubmit={handleSubmit}
          sx={{mt: 1}}
          validateOnBlur={true}
          validateOnChange={false}
          validationSchema={validationSchema}
        >
          {({submitForm, isSubmitting, touched, errors, isValid}) => (
            <Form style={{width: '100%'}}>
              <mui.Stack spacing={3} mt={3}>
                {!tokenIsValid.data
                  ? <mui.Alert severity="error">Password reset link is invalid or expired.</mui.Alert>
                  : <>
                    {commonErrors.map((error, i) => (
                      <mui.Alert key={i} severity="error">{error}</mui.Alert>
                    ))}
                    <Field
                      component={TextField}
                      error={touched.password && (!!errors.password || !!fieldErrors.password)}
                      helperText={touched.password && (errors.password || fieldErrors.password)}
                      InputProps={{autoComplete: 'off', fullWidth: true, type: 'password'}}
                      label="Password"
                      name="password"
                    />
                    <Field
                      component={TextField}
                      error={touched.password2 && (!!errors.password2 || !!fieldErrors.password2)}
                      helperText={touched.password2 && (errors.password2 || fieldErrors.password2)}
                      InputProps={{autoComplete: 'off', fullWidth: true, type: 'password'}}
                      label="Confirm password"
                      name="password2"
                    />
                    <mui.Button
                      disabled={
                        isSubmitting ||
                        !isValid ||
                        Object.keys(fieldErrors).length > 0 ||
                        Object.keys(touched).length === 0
                      }
                      fullWidth
                      onClick={submitForm}
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1.5,
                        backgroundColor: '#A8C02A',
                        '&:hover': {
                          backgroundColor: '#C1CC00',
                        },
                      }}
                      variant="contained"
                    >
                      Reset password
                    </mui.Button>
                  </>
                }
                <mui.Stack>
                  {!tokenIsValid.data && (
                    <Link to={routes.FORGOT_PASSWORD} variant="body2">
                      Request a new password reset link
                    </Link>
                  )}
                  <Link to={routes.LOGIN} variant="body2">
                    Back to login
                  </Link>
                </mui.Stack>
              </mui.Stack>
            </Form>
          )}
        </Formik>
      </mui.Box>
    </mui.Container>
  )
}