import * as mui from '@mui/material'
import React from 'react'

import {Calculate, CalculationInput, Currency, Query, Standards} from 'types/graphql/schema'
import {UseQueryBaseReturnType} from 'utils/graphql'

import {SectionInvestment} from '../SectionInvestment'
import {SectionShortTermReturns} from '../SectionShortTermReturns'
import {SectionLongTermReturnsReproduction} from '../SectionLongTermReturnsReproduction'
import {SectionLongTermReturnsHealth} from '../SectionLongTermReturnsHealth'
import {Dashboard} from '../Dashboard'
import {NotesState} from '../Dashboard/Controls'


export function Main(props: {
  calculate: (values: CalculationInput) => void
  calculationResult: Calculate
  commonErrors: string[]
  constants: UseQueryBaseReturnType & {data: Query}
  initialCurrency: Currency
  investmentDisabledColumns: boolean[]
  setInvestmentDisabledColumns: (columns: boolean[]) => void
  notesState: NotesState
  pdfRef?: React.RefObject<HTMLDivElement>
  standards: Standards
}) {
  return (
    <mui.Container
      maxWidth="xl"
      sx={{
        width: t => t.breakpoints.values.xl,
        ...(props.pdfRef
          ? {pt:'100px',}
          : {}
        )
      }}
    >
      <mui.Grid
        container
        spacing={3}
        sx={{
          ...(props.pdfRef
            ? {}
            : {'& > .MuiGrid-root': {pt:0}}
          )
        }}
      >
        <mui.Grid container item xs={9} md={9}>
          <mui.Stack gap={1}>
            {props.commonErrors.map((error, index) => (
              <mui.Alert key={index} severity="error">{error}</mui.Alert>
            ))}
            <SectionInvestment
              calculationResult={props.calculationResult}
              calculate={props.calculate}
              currencies={props.constants.data.currencies}
              disabledColumns={props.investmentDisabledColumns}
              setDisabledColumns={props.setInvestmentDisabledColumns}
              products={props.constants.data.products}
              standards={props.standards}
              isPdf={!props.pdfRef}
            />
            <SectionShortTermReturns
              calculate={props.calculate}
              calculationResult={props.calculationResult}
              isPdf={!props.pdfRef}
            />
            <SectionLongTermReturnsReproduction
              calculate={props.calculate}
              calculationResult={props.calculationResult}
              isPdf={!props.pdfRef}
            />
            <SectionLongTermReturnsHealth
              calculate={props.calculate}
              calculationResult={props.calculationResult}
              isPdf={!props.pdfRef}
            />
            <mui.Typography variant="caption" sx={{opacity: .5, textAlign: 'center'}}>
              <i>Underlying model from repro section was adapted from Cabrera 2012 (JDS 95:4683-4698)</i>
            </mui.Typography>
          </mui.Stack>
        </mui.Grid>
        <mui.Grid container item xs={3} md={3} position="relative">
          <Dashboard
            calculate={props.calculate}
            calculationResult={props.calculationResult}
            constants={props.constants}
            currencies={props.constants.data.currencies}
            feedingRates={props.standards.feedingRates.productFeedingRates}
            initialCurrency={props.initialCurrency}
            notesState={props.notesState}
            pdfRef={props.pdfRef}
            standards={props.standards}
          />
        </mui.Grid>
      </mui.Grid>
    </mui.Container>
  )
}