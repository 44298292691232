import * as mui from '@mui/material'
import { Field as BaseField, FieldAttributes, useFormikContext } from 'formik'
import { TextField } from 'formik-mui'
import React from 'react'

import {CalculationInput} from 'types/graphql/schema'


export type FieldProps = FieldAttributes<mui.TextFieldProps>


export function Field({
  calculate,
  ...props
}: {
  calculate: (values: CalculationInput) => void,
} & FieldProps) {
  const { values } = useFormikContext()
  return (
    <BaseField
      component={TextField}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        if (props.onBlur) {
          // noinspection TypeScriptValidateTypes
          props.onBlur(e)
        }
        calculate(values as CalculationInput)
      }}
      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {if (e.key === 'Enter') calculate(values as CalculationInput)}}
      type="number"
      {...props}
      sx={{
        '& input[type=number]': {
          '-moz-appearance': 'textfield',  // Hide up and down arrows on numeric field
        },
        '& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        ...props.sx,
      }}
    />
  )
}