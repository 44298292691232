import * as mui from '@mui/material'
import React from 'react'

import {Calculate, CalculationInput} from 'types/graphql/schema'

import {FieldSoB} from '../components/FieldSoB'
import {TableSection} from '../components/TableSection'
import {formatValue as f, formatUnits as fu} from '../utils'

import logoMilkPay from './assets/milkpay.svg'


export function SectionShortTermReturns(props: {
  calculate: (values: CalculationInput) => void
  calculationResult: Calculate
  isPdf: boolean
}) {
  const v = props.calculationResult
  return <TableSection
    colorGroupText="white"
    colorGroupBg="#A70A2D"
    colorTableBg="#F9EDED"
    colorResultBg="#A70A2D"
    columns={[[
      '',
      'Units',
      'Current',
      'Adjusted',
      'Difference',
      {
        content: <b>Milk Pricing & Bonus</b>,
        cellProps: {colSpan: 3},
        tooltip: <>
          - Please update these values with the most current milk pricing that best represent your region,
          accordingly.<br/>
          - You have the option to enter the price paid per lb or kg of Milk Volume or price paid per yield of Milk
          Components.<br/>
          - If your region only pays for Milk Components, for instance, then delete the pricing for Milk Volume (in case
          there is a number in there).<br/>
          - On the other hand, if your region pays for Milk Volume only, for instance, enter the pricing paid per unit
          of Milk Volume and delete the existing pricing for Milk Components.<br/>
          - If a producer is paid per kg or lb of Milk Volume and an extra bonus per kg or lb of Milk Fat above a
          certain level of Fat%, for instance, then the pricing per lb or kg of Fat can be used to account for that
          bonus accordingly. Or the Quality Premium option could be used for the same purpose.<br/>
          - The Quality Premium is a space to enter any premiums/bonus obtained by a specific producer.<br/>
        </>,
      },
    ]]}
    data={[
      [
        {content: 'Milk', borderBottom: true},
        {...fu(`${v.weightUnits}/cow/d`), borderBottom: true},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="shortTermReturnsMilkCurrent" />, borderBottom: true},
        {...f(v.shortTermReturns.milkPerCowDayWithProduct), borderBottom: true},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="shortTermReturnsMilkDifference" />, borderBottom: true},
        {
          content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="shortTermReturnsMilkFmmoPrice" sx={{textAlign: 'right'}} />,
          cellProps: {sx: {px: 0, '& input': {textAlign: 'right'}}},
        },
        f(`${v.currency.sign}/${v.weightUnits}`),
        {...f('Milk volume'), cellProps: {sx: {px: 0, textAlign: 'left'}}},
      ],
      [
        'Fat',
        fu('%'),
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="shortTermReturnsFatCurrent" />},
        f(v.shortTermReturns.fatPercent),
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="shortTermReturnsFatDifference" />},
        {
          content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="shortTermReturnsFatFmmoPrice" sx={{textAlign: 'right'}} />,
          cellProps: {sx: {px: 0, '& input': {textAlign: 'right'}}},
        },
        f(`${v.currency.sign}/${v.weightUnits}`),
        {...f('Fat'), cellProps: {sx: {px: 0, textAlign: 'left'}}},
      ],
      [
        'Protein',
        fu('%'),
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="shortTermReturnsProteinCurrent" />},
        f(v.shortTermReturns.proteinPercent),
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="shortTermReturnsProteinDifference" />},
        {
          content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="shortTermReturnsProteinFmmoPrice" sx={{textAlign: 'right'}} />,
          cellProps: {sx: {px: 0, '& input': {textAlign: 'right'}}},
        },
        f(`${v.currency.sign}/${v.weightUnits}`),
        {...f('Protein'), cellProps: {sx: {px: 0, textAlign: 'left'}}},
      ],
      [
        {content: 'Other solids', borderBottom: true},
        {...fu('%'), borderBottom: true},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="shortTermReturnsOtherSolidsCurrent" />, borderBottom: true},
        {...f(v.shortTermReturns.otherSolidsPercent), borderBottom: true},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="shortTermReturnsOtherSolidsDifference" />, borderBottom: true},
        {
          content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="shortTermReturnsOtherSolidsFmmoPrice" sx={{textAlign: 'right'}} />,
          cellProps: {sx: {px: 0, '& input': {textAlign: 'right'}}},
        },
        f(`${v.currency.sign}/${v.weightUnits}`),
        {...f('Other solids'), cellProps: {sx: {px: 0, textAlign: 'left'}}},
      ],
      [
        {content: 'ECM', borderBottom: true},
        {...fu(`${v.weightUnits}/cow/d`), borderBottom: true},
        {...f(v.shortTermReturns.ecmPerCowDay.current), borderBottom: true},
        {...f(v.shortTermReturns.ecmPerCowDay.withProduct), borderBottom: true},
        {...f(v.shortTermReturns.ecmPerCowDay.difference), borderBottom: true},
        {
          content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="shortTermReturnsPremiumFmmoPrice" sx={{textAlign: 'right'}} />,
          cellProps: {sx: {px: 0, '& input': {textAlign: 'right'}}},
        },
        {...f(`${v.currency.sign}/${v.weightUnits === 'lb' ? 'cwt' : 'kg'}`)},
        {
          content: <mui.Box>
            {f('Quality premium').content}
            {v.weightUnits === 'lb' && !props.isPdf &&
              <mui.Box sx={{position: 'absolute', bottom: '-32px', right: '280px'}}>
                <a href="https://www.milkpay.com/" target="_blank">
                  <img src={logoMilkPay+'#svgView(preserveAspectRatio(none))'} alt="MilkPay" width="70" height="35"/>
                </a>
              </mui.Box>
            }
          </mui.Box>,
          cellProps: {sx: {px: 0, textAlign: 'left'}},
        },
      ],
      [
        'Fat',
        fu(`${v.weightUnits}/cow/d`),
        f(v.shortTermReturns.fatPerCowDay.current, 3),
        f(v.shortTermReturns.fatPerCowDay.withProduct, 3),
        f(v.shortTermReturns.fatPerCowDay.difference, 3),
        '',
        '',
        ''
      ],
      [
        'Protein',
        fu(`${v.weightUnits}/cow/d`),
        f(v.shortTermReturns.proteinPerCowDay.current, 3),
        f(v.shortTermReturns.proteinPerCowDay.withProduct, 3),
        f(v.shortTermReturns.proteinPerCowDay.difference, 3),
        '',
        '',
        '',
      ],
      [
        'Other solids',
        fu(`${v.weightUnits}/cow/d`),
        f(v.shortTermReturns.otherSolidsPerCowDay.current, 3),
        f(v.shortTermReturns.otherSolidsPerCowDay.withProduct, 3),
        f(v.shortTermReturns.otherSolidsPerCowDay.difference, 3),
        '',
        '',
        '',
      ],
      [
        {content: 'Milk price', borderTop: true, cellProps: {rowSpan: 2}},
        {...fu(`${v.currency.sign}/cwt`), borderTop: true},
        {...f(v.shortTermReturns.impactCwtCost.current, 2), borderTop: true},
        {...f(v.shortTermReturns.impactCwtCost.withProduct, 2), borderTop: true},
        {...f(v.shortTermReturns.impactCwtCost.difference, 2), borderTop: true},
        '',
        '',
        '',
      ],
      [
        fu(`${v.currency.sign}/cow/d`),
        f(v.shortTermReturns.impactPerCowDayCost.current, 2),
        f(v.shortTermReturns.impactPerCowDayCost.withProduct, 2),
        f(v.shortTermReturns.impactPerCowDayCost.difference, 2),
        '',
        '',
        '',
      ],
      [
        {content: 'Milk revenue', borderTop: true},
        {...fu(`${v.currency.sign}/cow/yr`), borderTop: true},
        {...f(v.shortTermReturns.impactPerCowYearCost.current, 2), borderTop: true},
        {...f(v.shortTermReturns.impactPerCowYearCost.withProduct, 2), borderTop: true},
        {...f(v.shortTermReturns.impactPerCowYearCost.difference, 2), borderTop: true},
        '',
        '',
        '',
      ],
    ]}
    dataRowsCollapsible={[5, 6, 7, 9, 10]}
    groups={[
      {content: <>Milk<br/>Performance</>, firstRow: 0, titlePadding: '28px'},
    ]}
    groupsColumn=''
    isPdf={props.isPdf}
    result={{
      padding: '20px',
      title: 'Improved',
      tooltip: <>Gross Returns<br/>(Increased Revenue)</>,
      subtitle: 'Milk Production',
      units: `${v.currency.sign}/${v.resultsUnits}`,
      value: f(v.shortTermReturns.totalImpactCost.difference, 2).text,
    }}
  />
}