import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import { PrivateRoute } from 'components/PrivateRoute'

import { Index } from './pages/Index'
import { PageNotFound } from './pages/PageNotFound'

import { ForgotPassword } from './pages/auth/ForgotPassword'
import { Login } from './pages/auth/Login'
import { Register } from './pages/auth/Register'
import { ResetPassword } from './pages/auth/ResetPassword'


export const routes = {
  INDEX: '/',
  FORGOT_PASSWORD: '/forgot-password',
  LOGIN: '/login',
  REGISTER: '/register',
  RESET_PASSWORD: '/reset-password/:token',
}

export const router = createBrowserRouter([
  {
    path: routes.INDEX,
    element: <PrivateRoute component={<Index/>} />,
  }, {
    path: routes.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  }, {
    path: routes.LOGIN,
    element: <Login />,
  }, {
    path: routes.REGISTER,
    element: <Register />,
  }, {
    path: routes.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  { path: '*', element: <PageNotFound /> },
])