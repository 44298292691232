import React from 'react'

import {Calculate, CalculationInput} from 'types/graphql/schema'

import {FieldSoB} from '../components/FieldSoB'
import {TableSection} from '../components/TableSection'
import {formatValue as f, formatUnits as fu} from '../utils'


export function SectionLongTermReturnsReproduction(props: {
  calculate: (values: CalculationInput) => void
  calculationResult: Calculate
  isPdf: boolean
}) {
  const v = props.calculationResult
  return <TableSection
    colorGroupText="white"
    colorGroupBg="#C1CC00"
    colorTableBg="#F3F5CC"
    colorResultBg="#C1CC00"
    columns={[[
      '',
      'Units',
      'Current',
      'Adjusted',
      'Difference',
      {content: '', cellProps: {sx: {width: '250px'}}},
    ]]}
    data={[
      [
        {
          content: 'Reproduction Cost',
          tooltip: <>
            - Cost incurred by non-pregnant cows being bred.<br/>
            - This cost includes labor, hormones (if any), semen and insemination, and pregnancy diagnosis.<br/>
            - This is a hard value to come-up with and one that dairyman will not have at hand.<br/>
            - A rough estimative should be closer to $45.<br/>
          </>,
        },
        fu(`${v.currency.sign}/cow/mo`),
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsReproductionCostCurrent" />},
        '',
        '',
        {content: '', cellProps: {sx: {backgroundColor: '#F3F5CC'}}},
      ],
      [
        {
          content: 'Replacement Cost',
          tooltip: <>
            - Cost to buy a pregnant heifer ready to calve.<br/>
          </>,
        },
        fu(`${v.currency.sign}/cow`),
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsReplacementCostCurrent" />},
        '',
        '',
        {content: '', cellProps: {sx: {backgroundColor: '#F3F5CC'}}},
      ],
      [
        {
          content: 'Cull Value',
          tooltip: <>
            - Average price received for cows being culled (i.e., animals sold for meat purposes).<br/>
          </>,
        },
        fu(`${v.currency.sign}/${v.weightUnits} LW`),
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsCullValueCurrent" />},
        '',
        '',
        {content: '', cellProps: {sx: {backgroundColor: '#F3F5CC'}}},
      ],
      [
        {
          content: 'Calf Value',
          tooltip: <>
            - A weighted average price for male and female calves born on farm.<br/>
          </>,
        },
        fu(`${v.currency.sign}/calf`),
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsCalfValueCurrent" />},
        '',
        '',
        {content: '', cellProps: {sx: {backgroundColor: '#F3F5CC'}}},
      ],
      // [
      //   {
      //     content: 'Rolling Herd Average',
      //     tooltip: <>
      //       - It represents the average amount of milk produced by cows in the last 365 days.<br/>
      //       - This value represents production for when the cow was in milk and when she was dry as well.<br/>
      //       - Production data for cows that left the herd is included in this value for the number of days when they
      //       were in the herd during the 365-day period.<br/>
      //       - If a cow left the herd in mid-lactation, her production to that day is included in the RHA.<br/>
      //       - The RHA here is being estimated from the milk yield entered in the Milk Performance section above based on
      //       the following equation: herd average’s milk yield × 305 × 1.197. For example, if the herd’s average milk
      //       yield is 90 lb, the estimated RHA is 90 × 305 × 1.197 = 32,319. The number 1.197 equals 365 ÷ 305 and
      //       assumes that all the cows stayed in the herd and were milked for 305 days.<br/>
      //       - Due to model restrictions, the cut off is 48 lb MY (min.) and 80 lb MY (max.) or 22 and 36 kg MY (min. and
      //       max.). If it goes below or above those numbers, the model will hold RHA at 18,000 min. or 29,000 max.<br/>
      //     </>,
      //   },
      //   fu(`${v.weightUnits}/cow/yr`),
      //   f(v.longTermReturnsReproduction.rollingHerdAvg, 0),
      //   '',
      //   '',
      //   {content: '', cellProps: {sx: {backgroundColor: '#F0F2C0'}}},
      // ],
      [
        {
          content: 'Last Month to Breed',
          tooltip: <>
            - The time when the dairymen make the last service to a non-pregnant cow.<br/>
          </>,
        },
        fu('mo'),
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsLastMonthToBreedCurrent" />},
        '',
        '',
        {content: '', cellProps: {sx: {backgroundColor: '#F3F5CC'}}},
      ],
      [
        {
          content: 'Minimum Milk to Breed',
          tooltip: <>
            - Milk threshold below which the non-pregnant cow is removed from the herd.<br/>
          </>,
        },
        fu(`${v.weightUnits}/cow/d`),
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsMinimumMilkToBreedCurrent" />},
        '',
        '',
        {content: '', cellProps: {sx: {backgroundColor: '#F3F5CC'}}},
      ],
      [
        {
          content: 'Culling Rate',
          tooltip: <>
            - Herd annual culling rate provided on herd reports.<br/>
            - It refers to the percentage of animals leaving the herd during a one-year period.<br/>
            - The culling rate includes both culling (non-pregnant and pregnant) and mortality culls.<br/>
          </>,
          borderBottom: true,
        },
        {...fu('%/yr'), borderBottom: true},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsCullingRateCurrent" />, borderBottom: true},
        {content: '', borderBottom: true},
        {content: '', borderBottom: true},
        {content: '', cellProps: {sx: {backgroundColor: '#F3F5CC'}}},
      ],
      [
        {
          content: 'Pregnancy Rate',
          tooltip: <>
            - The typical length of an estrous cycle is 21 d.<br/>
            - Pregnancy rates are usually measured on farms every 28 to 30 d.<br/>
            - This metric represents the percentage of eligible cows that become pregnant during each cycle.<br/>
            - It is calculated by dividing the number of cows that become pregnant by the number of cows eligible for pregnancy during a given period.<br/>
            - In practice, the pregnancy rate is determined by multiplying the heat detection rate by the conception rate.<br/>
            - The 'current' herd average pregnancy rate of 25% is based on data from a reference study that surveyed multiple farms across the US, update it accordingly.<br/>
            - Research from the Universities of Cornell and Wisconsin has shown an average increase in pregnancy rates of about 14%.<br/>
            - A 14% increase in the 25% pregnancy rate results in an improved rate of approximately 28%, reflecting a 3% improvement.<br/>
            - The 'difference' or improvement in pregnancy rate is also adjustable and can be modified as needed.<br/>
          </>,
        },
        fu('%'),
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsPregnancyRateCurrent" />},
        {...f(v.longTermReturnsReproduction.pregnancyRateWithProduct, 0)},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsPregnancyRateDifference" />},
        {
          content: <></>,
          cellProps: {sx: {backgroundColor: '#F3F5CC'}, rowSpan: 20},
        },
      ],
      [
        {
          content: '30-60-d Pregnancy Loss',
          tooltip: <>
            - Pregnancy checks are usually conducted 30 and 60 d after AI.<br/>
            - This metric refers to the percentage of cows that suffer a pregnancy loss between the first and second
            pregnancy test.<br/>
            - Based on some research reference (including Toledo et al., 2017), the suggestion is that Methionine
            supplementation decreases pregnancy losses by ~40% (between the 1st and 2nd pregnancy check).<br/>
            - The default current incidence of 17% is based on the weighted average of heifers and cows from the
            reference provided here.<br/>
            - A total of 15 losses occurred between days 28 and 61 for the primiparous and multiparous cows, out of 90
            total control cows (15 ÷ 90 = 17%).<br/>
            - The default adjusted incidence of 10% (reduction of 7% units) is based on 9 losses between d 28 and 61 for
            primiparous and multiparous cows out of 90 total control cows (9 ÷ 90 = 10%).<br/>
            - The "difference" or reduction in preg losses is editable, therefore can be adjusted accordingly.<br/>
          </>,
          borderBottom: true,
        },
        {...fu('%'), borderBottom: true},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsPregnancyLossCurrent" />, borderBottom: true},
        {...f(v.longTermReturnsReproduction.pregnancyLossWithProduct, 0), borderBottom: true},
        {content: <FieldSoB calculate={props.calculate}  isPdf={props.isPdf} name="longTermReturnsPregnancyLossDifference" />, borderBottom: true},
      ],
      [
        'Calf Sales',
        fu(`${v.currency.sign}/cow/yr`),
        f(v.longTermReturnsReproduction.calfSales.current, 0),
        f(v.longTermReturnsReproduction.calfSales.withProduct, 0),
        f(v.longTermReturnsReproduction.calfSales.difference, 0),
      ],
      [
        'Mortality Cost',
        fu(`${v.currency.sign}/cow/yr`),
        f(v.longTermReturnsReproduction.mortalityCost.current, 0),
        f(v.longTermReturnsReproduction.mortalityCost.withProduct, 0),
        f(v.longTermReturnsReproduction.mortalityCost.difference, 0),
      ],
      [
        'Reproductive Cost',
        fu(`${v.currency.sign}/cow/yr`),
        f(v.longTermReturnsReproduction.reproductiveCost.current, 0),
        f(v.longTermReturnsReproduction.reproductiveCost.withProduct, 0),
        f(v.longTermReturnsReproduction.reproductiveCost.difference, 0),
      ],
      [
        'Reproductive Culling Cost',
        fu(`${v.currency.sign}/cow/yr`),
        f(v.longTermReturnsReproduction.reproductiveCullingCost.current, 0),
        f(v.longTermReturnsReproduction.reproductiveCullingCost.withProduct, 0),
        f(v.longTermReturnsReproduction.reproductiveCullingCost.difference, 0),
      ],
      [
        'Non-Reprod. Culling Cost',
        fu(`${v.currency.sign}/cow/yr`),
        f(v.longTermReturnsReproduction.nonReproductiveCullingCost.current, 0),
        f(v.longTermReturnsReproduction.nonReproductiveCullingCost.withProduct, 0),
        f(v.longTermReturnsReproduction.nonReproductiveCullingCost.difference, 0),
      ],
      [
        {content: 'Total Returns', borderTop: true},
        {...fu(`${v.currency.sign}/cow/yr`), borderTop: true},
        {...f(v.longTermReturnsReproduction.totalReturns.current, 0), borderTop: true},
        {...f(v.longTermReturnsReproduction.totalReturns.withProduct, 0), borderTop: true},
        {...f(v.longTermReturnsReproduction.totalReturns.difference, 2), borderTop: true},
      ],
    ]}
    // dataRowsCollapsible={[]}
    dataRowsCollapsible={[0, 1, 2, 3, 4, 5, 6, 14]}
    groups={[
      {content: <>Reproductive<br/>Performance</>, firstRow: 0, titlePadding: '44px'},
    ]}
    groupsColumn=''
    isPdf={props.isPdf}
    result={{
      padding: '32px',
      tooltip: <>Gross Returns<br/>(Savings with Reproduction)</>,
      title: 'Improved',
      subtitle: 'Reproduction',
      units: `${v.currency.sign}/${v.resultsUnits}`,
      value: f(v.longTermReturnsReproduction.totalReturnsFinal.difference, 2).text,
    }}
  />
}